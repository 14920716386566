(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('classlist-polyfill');

require('whatwg-fetch');

var _domready = require('domready');

var _domready2 = _interopRequireDefault(_domready);

var _requestAnimationFrame = require('./polyfills/request-animation-frame');

var _requestAnimationFrame2 = _interopRequireDefault(_requestAnimationFrame);

var _handle = require('./modules/handle');

var _handle2 = _interopRequireDefault(_handle);

var _enhance = require('./modules/enhance');

var _enhance2 = _interopRequireDefault(_enhance);

var _scrollListener = require('./modules/scroll-listener');

var _navToggler = require('./modules/nav-toggler');

var _objectFit = require('./modules/object-fit');

var _readspeakerConfig = require('./modules/readspeaker-config');

var _responsive = require('./modules/responsive');

var _disableHoverStylesOnScroll = require('./modules/disable-hover-styles-on-scroll');

var _disableHoverStylesOnScroll2 = _interopRequireDefault(_disableHoverStylesOnScroll);

var _accessibleCollapser = require('./modules/accessible-collapser');

var _classToggler = require('./modules/class-toggler');

var _contrastToggler = require('./modules/contrast-toggler');

var _lazyMedia = require('./modules/lazy-media');

var _searchToggler = require('./modules/search-toggler');

var _gtmEvent = require('./modules/gtm-event');

var _mailchimpSignup = require('./modules/mailchimp-signup');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Import enhancers
var executeOnReady = function executeOnReady() {
  (0, _disableHoverStylesOnScroll2.default)(); // Disable hover styles on scroll
  (0, _scrollListener.enhancer)(); // Initialise central scroll listener
  (0, _responsive.enhancer)(); // Set document width on resize and orientation change
  window.requestAnimationFrame = window.requestAnimationFrame || _requestAnimationFrame2.default;
};

// Import handlers


// Import functions that are executed on DOMready regardless of DOM


// Handler and Enhancer utility
// Import libraries and polyfills


function main() {
  executeOnReady();

  (0, _handle2.default)({
    accessibleCollapser: _accessibleCollapser.handler,
    classToggler: _classToggler.handler,
    contrastToggler: _contrastToggler.handler,
    gtmEventHandler: _gtmEvent.handler,
    lazyMediaHandler: _lazyMedia.handler,
    searchToggler: _searchToggler.handler,
    navTogglerHandler: _navToggler.handler
  });

  (0, _enhance2.default)({
    gtmEventEnhancer: _gtmEvent.enhancer,
    navTogglerEnhancer: _navToggler.enhancer,
    objectFit: _objectFit.enhancer,
    readspeakerConfig: _readspeakerConfig.enhancer,
    mailchimpSignup: _mailchimpSignup.enhancer
  });
}

(0, _domready2.default)(function () {
  main();
});

},{"./modules/accessible-collapser":2,"./modules/class-toggler":3,"./modules/contrast-toggler":4,"./modules/disable-hover-styles-on-scroll":5,"./modules/enhance":6,"./modules/gtm-event":7,"./modules/handle":8,"./modules/lazy-media":9,"./modules/mailchimp-signup":10,"./modules/nav-toggler":11,"./modules/object-fit":12,"./modules/readspeaker-config":13,"./modules/responsive":14,"./modules/scroll-listener":15,"./modules/search-toggler":16,"./polyfills/request-animation-frame":18,"classlist-polyfill":19,"domready":20,"whatwg-fetch":22}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handler = undefined;

var _classToggler = require('./class-toggler');

var handler = exports.handler = function handler(el, e) {
  (0, _classToggler.handler)(el, e);

  el.setAttribute('aria-expanded', el.getAttribute('aria-expanded') === 'false');
}; /**
    * Toggles aria-expanded attributes, while using the classToggler
    */

},{"./class-toggler":3}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DATA_ATTR_TARGET = 'data-target';
var DATA_ATTR_CLASS = 'data-target-class';
var DATA_CALLBACK = 'data-callback';

var triggerIsValid = function triggerIsValid(item) {
  return item.getAttribute(DATA_ATTR_TARGET) && item.getAttribute(DATA_ATTR_CLASS);
};

var handler = exports.handler = function handler(elm, e) {
  e.preventDefault();
  if (!triggerIsValid(elm)) {
    return;
  }
  var target = document.querySelector(elm.getAttribute(DATA_ATTR_TARGET));
  if (!target) {
    return;
  }
  target.classList.toggle(elm.getAttribute(DATA_ATTR_CLASS));
  if (elm.hasAttribute(DATA_CALLBACK)) {
    window[elm.getAttribute(DATA_CALLBACK)]();
  }
};

},{}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var COOKIE_NAME = 'studio-i-contrast';

var createCookie = function createCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires="' + date.toGMTString();
  }
  document.cookie = name + '=' + value + ' ' + expires + '; path=/';
};

var eraseCookie = function eraseCookie(name) {
  createCookie(name, '', -1);
};

var toggleBodyClass = function toggleBodyClass(el) {
  document.body.classList.toggle('has-high-contrast');
};

var toggleElClass = function toggleElClass(el) {
  el.classList.toggle('is-toggled');
};

var toggleCookie = function toggleCookie(el) {
  createCookie(COOKIE_NAME, el.classList.contains('is-toggled'), 7);
  if (document.body.classList.contains('has-high-contrast')) {
    eraseCookie(COOKIE_NAME);
  }
};

var handler = exports.handler = function handler(el, e) {
  toggleElClass(el);
  toggleCookie(el);
  toggleBodyClass();
};

},{}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _scrollListener = require('./scroll-listener');

/**
 * Add class to the body when scrolling.
 * This class disabled pointer-events in the CSS. Greatly enhanced performance.
 */

exports.default = function () {
  var timer = void 0;
  (0, _scrollListener.register)('hover-styles', function () {
    clearTimeout(timer);
    if (!document.body.classList.contains('disable-hover')) {
      document.body.classList.add('disable-hover');
    }
    timer = setTimeout(function () {
      document.body.classList.remove('disable-hover');
    }, 300);
  });
};

},{"./scroll-listener":15}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _util = require('./util');

exports.default = function (enhancers) {
  if (!enhancers) {
    return;
  }
  var enhancerElms = document.querySelectorAll('[data-enhancer]');
  (0, _util.forEach)(enhancerElms, function (elm) {
    // allow multiple comma-separated enhancers
    var enhancerCollection = elm.getAttribute('data-enhancer');
    enhancerCollection.split(',').forEach(function (enhancer) {
      if (typeof enhancers[enhancer] === 'function') {
        enhancers[enhancer](elm);
      } else if (window.console && typeof console.log === 'function') {
        console.log('Non-existing enhancer: "%s" on %o', enhancer, undefined);
      }
    });
  });
}; /**
    * Runner of enhancers
    */

},{"./util":17}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var getAttributes = function getAttributes(el) {
  return {
    type: el.getAttribute('data-event-type'),
    category: el.getAttribute('data-event-cat'),
    action: el.getAttribute('data-event-action'),
    label: el.getAttribute('data-event-label'),
    value: el.getAttribute('data-event-value')
  };
};

var hasProductionDefined = function hasProductionDefined() {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer.length && window.dataLayer[0].platformEnvironment === 'production';
};

var pushEvent = exports.pushEvent = function pushEvent(data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
  if (!hasProductionDefined()) {
    console.log('Tracking event', data);
  }
};

var trackEvent = exports.trackEvent = function trackEvent(args) {
  if (!args || !args.type || !args.category || !args.action) {
    console.error('Missing arguments in trackEvent', args);
    return;
  }
  var data = {
    eventType: args.type,
    eventCategory: args.category,
    eventAction: args.action,
    eventLabel: args.label,
    eventValue: args.value
  };
  pushEvent(_extends({ event: args.type }, data));
};

var handler = exports.handler = function handler(el, e) {
  trackEvent(getAttributes(el));
};

var enhancer = exports.enhancer = function enhancer(el) {
  if (undefined.nodeName.toUpperCase() !== 'FORM') {
    console.log('I don\'t know how to enhance non-forms.');
  }
  undefined.addEventListener('submit', function (e) {
    trackEvent(getAttributes(el));
  });
};

},{}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Runner of handlers
 */
var findElementWithHandler = function findElementWithHandler(elm) {
  if (!elm || elm.tagName === 'HTML') {
    return null;
  }
  if (elm.getAttribute('data-handler')) {
    return elm;
  }
  if (!elm.parentNode || elm.parentNode.nodeName === 'BODY') {
    return false;
  }
  return findElementWithHandler(elm.parentNode);
};

exports.default = function (handlers) {
  if (!handlers) {
    throw new Error('Nothing to handle');
  }

  document.documentElement.addEventListener('click', function (e) {
    if (e.target.tagName === 'HTML') {
      return;
    }

    var trigger = findElementWithHandler(e.target || e.srcElement);
    if (!trigger) {
      return;
    }

    var handlerCollection = trigger.getAttribute('data-handler');
    if (!handlerCollection) {
      return;
    }

    if (trigger.tagName === 'A' && (e.metaKey || e.ctrlKey || e.shiftKey)) {
      // honour default behaviour on <a>s when using modifier keys when clicking
      // meta / ctrl open in new tab
      // shift opens in a new window
      return;
    }

    handlerCollection.split(',').forEach(function (handler) {
      if (typeof handlers[handler] === 'function') {
        handlers[handler](trigger, e);
      } else if (console && console.log) {
        console.log('Non-existing handler: "%s" on %o', handler, undefined);
      }
    });
  });
};

},{}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handler = undefined;

var _util = require('./util');

var IFRAME_ATTRIBUTE = 'data-iframe-embed';

var isHeroEmbed = function isHeroEmbed(el) {
  return (0, _util.closest)(el, '.hero');
};

var setPlayingClass = function setPlayingClass(el) {
  if (isHeroEmbed(el)) {
    (0, _util.closest)(el, '.hero').classList.add('is-playing-video');
  } else {
    (0, _util.closest)(el, '.video-embed').classList.add('is-playing-video');
  }
};

var setIframeAttributes = function setIframeAttributes(el) {
  var iframe = el.getAttribute(IFRAME_ATTRIBUTE);
  el.nextElementSibling.innerHTML = iframe;

  var embeddedIframe = el.nextElementSibling.querySelector('iframe');
  embeddedIframe.setAttribute("allowfullscreen", "allowfullscreen");
  embeddedIframe.setAttribute('allow', 'autoplay');

  var src = embeddedIframe.getAttribute('src');
  src += (src.indexOf('?') === -1 ? '?' : '&') + 'autoplay=1';
  embeddedIframe.src = src;
};

var handler = exports.handler = function handler(el, e) {
  e.preventDefault();
  setPlayingClass(el);
  setIframeAttributes(el);
  el.parentNode.removeChild(el);
};

},{"./util":17}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var isSubmitting = void 0;

var MERGE_FIELD_ID_PREFIX = 'mce-';

var buildUrlQuery = function buildUrlQuery(data) {
  return Object.keys(data).map(function (k) {
    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
  }).join('&');
};

var getMergeFields = function getMergeFields(form) {
  var $fields = form.querySelectorAll('input, select, textarea');
  return [].concat(_toConsumableArray($fields)).filter(function (el) {
    return el.id.indexOf(MERGE_FIELD_ID_PREFIX) === 0;
  });
};

var redirectTo = function redirectTo(url) {
  window.location.href = url;
};

var getMergeData = function getMergeData(form) {
  return getMergeFields(form).reduce(function (acc, cur) {
    acc[cur.name] = cur.value;
    return acc;
  }, {});
};

var submit = function submit(form) {
  if (isSubmitting) {
    return;
  }
  isSubmitting = true;

  var $honey = form.querySelector('input[name="b_honey"]');
  if ($honey.value !== '') {
    return;
  }

  var $button = form.querySelector('button');
  var $error = form.querySelector('.js-error');
  var $email = form.querySelector('input[name="EMAIL"]');
  var listId = form.getAttribute('data-list-id');
  var successUrl = form.getAttribute('data-success-url');
  var language = form.getAttribute('data-language');

  $button.classList.remove('is-completed');
  $button.classList.add('is-loading');
  $error.setAttribute('aria-hidden', 'true');
  $error.innerText = "Something went wrong. Try again.";

  var payload = {
    action: 'grrr_mailchimp_signup',
    email: $email.value,
    merge_fields: JSON.stringify(getMergeData(form)),
    list_id: listId
  };

  if (language) {
    payload.language = language;
  }

  fetch(window.ajax_url, {
    method: 'POST',
    body: buildUrlQuery(payload),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
    credentials: 'same-origin'
  }).then(function (response) {
    return response.json();
  }).then(function (data) {
    $button.classList.remove('is-loading');
    if (!data.success) {
      var error = JSON.parse(data.data.result.body);
      $error.innerText = error.title;
      $error.setAttribute('aria-hidden', 'false');
    } else {
      if (successUrl) {
        redirectTo(successUrl);
      }
      getMergeFields(form).map(function (el) {
        el.value = '';
      });
      $error.innerText = 'Inschrijving geslaagd';
      $error.setAttribute('aria-hidden', 'false');
      $button.classList.add('is-completed');
    }
    isSubmitting = false;
  });
};

var enhancer = exports.enhancer = function enhancer(el) {
  el.addEventListener('submit', function (e) {
    e.preventDefault();
    submit(el);
  });
};

},{}],11:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enhancer = exports.handler = undefined;

var _responsive = require('./responsive');

var NAVIGATION_SELECTOR = '.js-site-header-navigation';

var toggleAttribute = function toggleAttribute(element, condition, className) {
  element.classList.toggle(className, condition);
  element.setAttribute('aria-hidden', !condition);
};

var getNavigation = function getNavigation() {
  return document.querySelector(NAVIGATION_SELECTOR);
};

var repositionHeaderElements = function repositionHeaderElements(el) {
  var primaryMenu = document.querySelector('.js-nav-primary');
  var secondaryMenu = document.querySelector('.js-nav-secondary');
  var languageSwitch = document.querySelector('.js-language-switch');
  var a11yMenu = document.querySelector('.js-a11y-menu');
  var searchForm = document.querySelector('.js-search-form-container');
  var ctaButton = document.querySelector('.js-cta-button');
  var topContainer = document.querySelector('.js-top-container');
  var bottomContainer = document.querySelector('.js-bottom-container');

  if ((0, _responsive.matchesBreakpoint)('medium')) {
    topContainer.appendChild(secondaryMenu);
    if (languageSwitch) {
      topContainer.appendChild(languageSwitch);
    }
    topContainer.appendChild(a11yMenu);
    bottomContainer.appendChild(primaryMenu);
    bottomContainer.appendChild(searchForm);
    if (ctaButton) {
      bottomContainer.appendChild(ctaButton);
    }
  } else {
    if (languageSwitch) {
      el.parentNode.insertBefore(languageSwitch, el);
    }
    topContainer.appendChild(searchForm);
    topContainer.appendChild(a11yMenu);
    topContainer.appendChild(primaryMenu);
    topContainer.appendChild(ctaButton);
    bottomContainer.appendChild(secondaryMenu);
  }
};

var resetState = function resetState(el, navigationEl) {
  if (el.classList.contains('is-toggled')) {
    navigationEl.classList.add('is-visible');
    document.body.classList.add('has-overlay');
    if ((0, _responsive.matchesBreakpoint)('medium')) {
      el.classList.remove('is-toggled');
      document.body.classList.remove('has-overlay');
    }
  } else {
    document.body.classList.remove('has-overlay');
  }
};

var handler = exports.handler = function handler(el, e) {
  var navigationEl = getNavigation();
  toggleAttribute(navigationEl, !navigationEl.classList.contains('is-visible'), 'is-visible');
  el.classList.toggle('is-toggled');
  resetState(el, navigationEl);
};

var enhancer = exports.enhancer = function enhancer(el) {
  var navigationEl = getNavigation();
  repositionHeaderElements(el);
  toggleAttribute(navigationEl, (0, _responsive.matchesBreakpoint)('medium'), 'is-visible');
  toggleAttribute(el, !(0, _responsive.matchesBreakpoint)('medium'), 'is-visible');
  var resizeActions = function resizeActions() {
    repositionHeaderElements(el);
    toggleAttribute(navigationEl, (0, _responsive.matchesBreakpoint)('medium'), 'is-visible');
    toggleAttribute(el, !(0, _responsive.matchesBreakpoint)('medium'), 'is-visible');
    resetState(el, navigationEl);
  };

  var lastWidth = document.documentElement.clientWidth;
  window.addEventListener('resize', function (e) {
    if (document.documentElement.clientWidth !== lastWidth) {
      resizeActions();
      lastWidth = document.documentElement.clientWidth;
    }
  });
};

},{"./responsive":14}],12:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enhancer = undefined;

var _objectFitImages = require('object-fit-images');

var _objectFitImages2 = _interopRequireDefault(_objectFitImages);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Polyfill for object-fit
 * @see: https://github.com/bfred-it/object-fit-images/
 */
var enhancer = exports.enhancer = function enhancer(elm, e) {
  if (!Modernizr.objectfit) {
    (0, _objectFitImages2.default)(elm);
  }
};

},{"object-fit-images":21}],13:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
* ReadSpeaker config
* @see: https://admin.readspeaker.com/portal/index.php/documentation
*/
var enhancer = exports.enhancer = function enhancer(elm, e) {
  window.rsConf = {
    ui: {
      disableDetachment: true,
      tools: {
        voicesettings: true
      }
    }
  };
};

},{}],14:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Responsive breakpoint registry
 */

var docWidth = void 0;
var BREAKPOINT_TINY = 480;
var BREAKPOINT_SMALL = 640;
var BREAKPOINT_MEDIUM = 960;
var BREAKPOINT_LARGE = 1280;
var BREAKPOINT_EXTRA_LARGE = 1680;

var setDocWidth = function setDocWidth() {
  docWidth = window.innerWidth || document.documentElement.clientWidth;
};

var getDocWidth = exports.getDocWidth = function getDocWidth() {
  if (!docWidth) {
    setDocWidth();
  }
  return docWidth;
};

var matchesBreakpoint = exports.matchesBreakpoint = function matchesBreakpoint(breakpoint) {
  switch (breakpoint) {
    case 'tiny':
      return getDocWidth() >= BREAKPOINT_TINY;
    case 'small':
      return getDocWidth() >= BREAKPOINT_SMALL;
    case 'medium':
      return getDocWidth() >= BREAKPOINT_MEDIUM;
    case 'large':
      return getDocWidth() >= BREAKPOINT_LARGE;
    case 'extraLarge':
      return getDocWidth() >= BREAKPOINT_EXTRA_LARGE;
    default:
      return false;
  }
};

var getCurrentBreakpoint = exports.getCurrentBreakpoint = function getCurrentBreakpoint() {
  var breakpoints = ['tiny', 'small', 'medium', 'large', 'extraLarge'];
  var matches = breakpoints.filter(matchesBreakpoint);
  return matches[matches.length - 1];
};

var enhancer = exports.enhancer = function enhancer() {
  window.addEventListener('resize', setDocWidth);
  window.addEventListener('orientationchange', setDocWidth);
};

},{}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isVisible = exports.isScrolledPast = exports.register = exports.enhancer = undefined;

var _util = require('./util');

var listeners = {};

var onScroll = function onScroll(e) {
  Object.keys(listeners).forEach(function (key) {
    if (typeof listeners[key] === 'function') {
      listeners[key](e);
    }
  });
};

var enhancer = exports.enhancer = function enhancer() {
  window.addEventListener('scroll', onScroll);
};

var register = exports.register = function register(key, fn) {
  listeners[key] = fn;
};

// Can be called with element or with number
var isScrolledPast = exports.isScrolledPast = function isScrolledPast(elm) {
  if (typeof elm === 'number') {
    return (0, _util.getScrollPosition)().y >= elm;
  }
  var scrollY = (0, _util.getScrollPosition)().y;
  return scrollY + elm.getBoundingClientRect().top <= scrollY;
};

var isVisible = exports.isVisible = function isVisible(elm) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return elm.getBoundingClientRect().top - window.innerHeight <= offset;
};

},{"./util":17}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var handler = exports.handler = function handler(el, e) {
  var searchForm = document.querySelector('.js-search-form');
  var searchInput = document.querySelector('.js-search-input');
  var primaryNav = document.querySelector('.js-nav-primary');

  el.setAttribute('aria-expanded', el.getAttribute('aria-expanded') === 'false');
  searchForm.classList.toggle('is-expanded', el.getAttribute('aria-expanded') === 'true');
  searchForm.classList.toggle('is-collapsed', el.getAttribute('aria-expanded') === 'false');
  primaryNav.classList.toggle('is-inactive', el.getAttribute('aria-expanded') === 'true');

  if (el.getAttribute('aria-expanded') === 'true') {
    searchInput.focus();
  } else {
    el.focus();
  }
};

},{}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var getScrollPosition = exports.getScrollPosition = function getScrollPosition() {
  var supportPageOffset = window.pageXOffset !== undefined;
  var isCSS1Compat = (document.compatMode || "") === "CSS1Compat";

  /* eslint-disable no-nested-ternary */
  var x = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;

  var y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
  return {
    x: x, y: y
  };
};

var closest = exports.closest = function closest(elm, selector) {
  var matchesFn = void 0;

  // find vendor prefix
  ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
    if (typeof document.body[fn] === 'function') {
      matchesFn = fn;
      return true;
    }
    return false;
  });

  // traverse parents
  /* eslint-disable no-param-reassign */
  while (elm !== null) {
    var parent = elm.parentElement;
    if (parent !== null && parent[matchesFn](selector)) {
      return parent;
    }
    elm = parent;
  }

  return null;
};

var head = exports.head = function head(a) {
  return a[0];
};

var forEach = exports.forEach = function forEach(a, fn) {
  return Array.prototype.forEach.call(a, fn);
};

var filter = exports.filter = function filter(a, fn) {
  return Array.prototype.filter.call(a, fn);
};

var map = exports.map = function map(a, fn) {
  return Array.prototype.map.call(a, fn);
};

var not = exports.not = function not(fn) {
  return function () {
    return !fn.apply(undefined, arguments);
  };
};

var objectByKey = exports.objectByKey = function objectByKey(arr, key) {
  return arr.reduce(function (prev, curr) {
    if (typeof curr[key] === 'undefined') {
      return prev;
    }
    prev[curr[key]] = curr;
    return prev;
  }, {});
};

},{}],18:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

/**
 * Simple requestAnimationFrame
 * @see http://elektronotdienst-nuernberg.de/bugs/requestAnimationFrame.html
 */
exports.default = function (c) {
  return setTimeout(function () {
    c(+new Date());
  }, 30);
};

},{}],19:[function(require,module,exports){
/*
 * classList.js: Cross-browser full element.classList implementation.
 * 1.1.20170427
 *
 * By Eli Grey, http://eligrey.com
 * License: Dedicated to the public domain.
 *   See https://github.com/eligrey/classList.js/blob/master/LICENSE.md
 */

/*global self, document, DOMException */

/*! @source http://purl.eligrey.com/github/classList.js/blob/master/classList.js */

if ("document" in window.self) {

// Full polyfill for browsers with no classList support
// Including IE < Edge missing SVGElement.classList
if (!("classList" in document.createElement("_")) 
	|| document.createElementNS && !("classList" in document.createElementNS("http://www.w3.org/2000/svg","g"))) {

(function (view) {

"use strict";

if (!('Element' in view)) return;

var
	  classListProp = "classList"
	, protoProp = "prototype"
	, elemCtrProto = view.Element[protoProp]
	, objCtr = Object
	, strTrim = String[protoProp].trim || function () {
		return this.replace(/^\s+|\s+$/g, "");
	}
	, arrIndexOf = Array[protoProp].indexOf || function (item) {
		var
			  i = 0
			, len = this.length
		;
		for (; i < len; i++) {
			if (i in this && this[i] === item) {
				return i;
			}
		}
		return -1;
	}
	// Vendors: please allow content code to instantiate DOMExceptions
	, DOMEx = function (type, message) {
		this.name = type;
		this.code = DOMException[type];
		this.message = message;
	}
	, checkTokenAndGetIndex = function (classList, token) {
		if (token === "") {
			throw new DOMEx(
				  "SYNTAX_ERR"
				, "An invalid or illegal string was specified"
			);
		}
		if (/\s/.test(token)) {
			throw new DOMEx(
				  "INVALID_CHARACTER_ERR"
				, "String contains an invalid character"
			);
		}
		return arrIndexOf.call(classList, token);
	}
	, ClassList = function (elem) {
		var
			  trimmedClasses = strTrim.call(elem.getAttribute("class") || "")
			, classes = trimmedClasses ? trimmedClasses.split(/\s+/) : []
			, i = 0
			, len = classes.length
		;
		for (; i < len; i++) {
			this.push(classes[i]);
		}
		this._updateClassName = function () {
			elem.setAttribute("class", this.toString());
		};
	}
	, classListProto = ClassList[protoProp] = []
	, classListGetter = function () {
		return new ClassList(this);
	}
;
// Most DOMException implementations don't allow calling DOMException's toString()
// on non-DOMExceptions. Error's toString() is sufficient here.
DOMEx[protoProp] = Error[protoProp];
classListProto.item = function (i) {
	return this[i] || null;
};
classListProto.contains = function (token) {
	token += "";
	return checkTokenAndGetIndex(this, token) !== -1;
};
classListProto.add = function () {
	var
		  tokens = arguments
		, i = 0
		, l = tokens.length
		, token
		, updated = false
	;
	do {
		token = tokens[i] + "";
		if (checkTokenAndGetIndex(this, token) === -1) {
			this.push(token);
			updated = true;
		}
	}
	while (++i < l);

	if (updated) {
		this._updateClassName();
	}
};
classListProto.remove = function () {
	var
		  tokens = arguments
		, i = 0
		, l = tokens.length
		, token
		, updated = false
		, index
	;
	do {
		token = tokens[i] + "";
		index = checkTokenAndGetIndex(this, token);
		while (index !== -1) {
			this.splice(index, 1);
			updated = true;
			index = checkTokenAndGetIndex(this, token);
		}
	}
	while (++i < l);

	if (updated) {
		this._updateClassName();
	}
};
classListProto.toggle = function (token, force) {
	token += "";

	var
		  result = this.contains(token)
		, method = result ?
			force !== true && "remove"
		:
			force !== false && "add"
	;

	if (method) {
		this[method](token);
	}

	if (force === true || force === false) {
		return force;
	} else {
		return !result;
	}
};
classListProto.toString = function () {
	return this.join(" ");
};

if (objCtr.defineProperty) {
	var classListPropDesc = {
		  get: classListGetter
		, enumerable: true
		, configurable: true
	};
	try {
		objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
	} catch (ex) { // IE 8 doesn't support enumerable:true
		// adding undefined to fight this issue https://github.com/eligrey/classList.js/issues/36
		// modernie IE8-MSW7 machine has IE8 8.0.6001.18702 and is affected
		if (ex.number === undefined || ex.number === -0x7FF5EC54) {
			classListPropDesc.enumerable = false;
			objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
		}
	}
} else if (objCtr[protoProp].__defineGetter__) {
	elemCtrProto.__defineGetter__(classListProp, classListGetter);
}

}(window.self));

}

// There is full or partial native classList support, so just check if we need
// to normalize the add/remove and toggle APIs.

(function () {
	"use strict";

	var testElement = document.createElement("_");

	testElement.classList.add("c1", "c2");

	// Polyfill for IE 10/11 and Firefox <26, where classList.add and
	// classList.remove exist but support only one argument at a time.
	if (!testElement.classList.contains("c2")) {
		var createMethod = function(method) {
			var original = DOMTokenList.prototype[method];

			DOMTokenList.prototype[method] = function(token) {
				var i, len = arguments.length;

				for (i = 0; i < len; i++) {
					token = arguments[i];
					original.call(this, token);
				}
			};
		};
		createMethod('add');
		createMethod('remove');
	}

	testElement.classList.toggle("c3", false);

	// Polyfill for IE 10 and Firefox <24, where classList.toggle does not
	// support the second argument.
	if (testElement.classList.contains("c3")) {
		var _toggle = DOMTokenList.prototype.toggle;

		DOMTokenList.prototype.toggle = function(token, force) {
			if (1 in arguments && !this.contains(token) === !force) {
				return force;
			} else {
				return _toggle.call(this, token);
			}
		};

	}

	testElement = null;
}());

}

},{}],20:[function(require,module,exports){
/*!
  * domready (c) Dustin Diaz 2014 - License MIT
  */
!function (name, definition) {

  if (typeof module != 'undefined') module.exports = definition()
  else if (typeof define == 'function' && typeof define.amd == 'object') define(definition)
  else this[name] = definition()

}('domready', function () {

  var fns = [], listener
    , doc = document
    , hack = doc.documentElement.doScroll
    , domContentLoaded = 'DOMContentLoaded'
    , loaded = (hack ? /^loaded|^c/ : /^loaded|^i|^c/).test(doc.readyState)


  if (!loaded)
  doc.addEventListener(domContentLoaded, listener = function () {
    doc.removeEventListener(domContentLoaded, listener)
    loaded = 1
    while (listener = fns.shift()) listener()
  })

  return function (fn) {
    loaded ? setTimeout(fn, 0) : fns.push(fn)
  }

});

},{}],21:[function(require,module,exports){
/*! npm.im/object-fit-images 3.2.3 */
'use strict';

var OFI = 'bfred-it:object-fit-images';
var propRegex = /(object-fit|object-position)\s*:\s*([-\w\s%]+)/g;
var testImg = typeof Image === 'undefined' ? {style: {'object-position': 1}} : new Image();
var supportsObjectFit = 'object-fit' in testImg.style;
var supportsObjectPosition = 'object-position' in testImg.style;
var supportsOFI = 'background-size' in testImg.style;
var supportsCurrentSrc = typeof testImg.currentSrc === 'string';
var nativeGetAttribute = testImg.getAttribute;
var nativeSetAttribute = testImg.setAttribute;
var autoModeEnabled = false;

function createPlaceholder(w, h) {
	return ("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" + w + "' height='" + h + "'%3E%3C/svg%3E");
}

function polyfillCurrentSrc(el) {
	if (el.srcset && !supportsCurrentSrc && window.picturefill) {
		var pf = window.picturefill._;
		// parse srcset with picturefill where currentSrc isn't available
		if (!el[pf.ns] || !el[pf.ns].evaled) {
			// force synchronous srcset parsing
			pf.fillImg(el, {reselect: true});
		}

		if (!el[pf.ns].curSrc) {
			// force picturefill to parse srcset
			el[pf.ns].supported = false;
			pf.fillImg(el, {reselect: true});
		}

		// retrieve parsed currentSrc, if any
		el.currentSrc = el[pf.ns].curSrc || el.src;
	}
}

function getStyle(el) {
	var style = getComputedStyle(el).fontFamily;
	var parsed;
	var props = {};
	while ((parsed = propRegex.exec(style)) !== null) {
		props[parsed[1]] = parsed[2];
	}
	return props;
}

function setPlaceholder(img, width, height) {
	// Default: fill width, no height
	var placeholder = createPlaceholder(width || 1, height || 0);

	// Only set placeholder if it's different
	if (nativeGetAttribute.call(img, 'src') !== placeholder) {
		nativeSetAttribute.call(img, 'src', placeholder);
	}
}

function onImageReady(img, callback) {
	// naturalWidth is only available when the image headers are loaded,
	// this loop will poll it every 100ms.
	if (img.naturalWidth) {
		callback(img);
	} else {
		setTimeout(onImageReady, 100, img, callback);
	}
}

function fixOne(el) {
	var style = getStyle(el);
	var ofi = el[OFI];
	style['object-fit'] = style['object-fit'] || 'fill'; // default value

	// Avoid running where unnecessary, unless OFI had already done its deed
	if (!ofi.img) {
		// fill is the default behavior so no action is necessary
		if (style['object-fit'] === 'fill') {
			return;
		}

		// Where object-fit is supported and object-position isn't (Safari < 10)
		if (
			!ofi.skipTest && // unless user wants to apply regardless of browser support
			supportsObjectFit && // if browser already supports object-fit
			!style['object-position'] // unless object-position is used
		) {
			return;
		}
	}

	// keep a clone in memory while resetting the original to a blank
	if (!ofi.img) {
		ofi.img = new Image(el.width, el.height);
		ofi.img.srcset = nativeGetAttribute.call(el, "data-ofi-srcset") || el.srcset;
		ofi.img.src = nativeGetAttribute.call(el, "data-ofi-src") || el.src;

		// preserve for any future cloneNode calls
		// https://github.com/bfred-it/object-fit-images/issues/53
		nativeSetAttribute.call(el, "data-ofi-src", el.src);
		if (el.srcset) {
			nativeSetAttribute.call(el, "data-ofi-srcset", el.srcset);
		}

		setPlaceholder(el, el.naturalWidth || el.width, el.naturalHeight || el.height);

		// remove srcset because it overrides src
		if (el.srcset) {
			el.srcset = '';
		}
		try {
			keepSrcUsable(el);
		} catch (err) {
			if (window.console) {
				console.warn('https://bit.ly/ofi-old-browser');
			}
		}
	}

	polyfillCurrentSrc(ofi.img);

	el.style.backgroundImage = "url(\"" + ((ofi.img.currentSrc || ofi.img.src).replace(/"/g, '\\"')) + "\")";
	el.style.backgroundPosition = style['object-position'] || 'center';
	el.style.backgroundRepeat = 'no-repeat';
	el.style.backgroundOrigin = 'content-box';

	if (/scale-down/.test(style['object-fit'])) {
		onImageReady(ofi.img, function () {
			if (ofi.img.naturalWidth > el.width || ofi.img.naturalHeight > el.height) {
				el.style.backgroundSize = 'contain';
			} else {
				el.style.backgroundSize = 'auto';
			}
		});
	} else {
		el.style.backgroundSize = style['object-fit'].replace('none', 'auto').replace('fill', '100% 100%');
	}

	onImageReady(ofi.img, function (img) {
		setPlaceholder(el, img.naturalWidth, img.naturalHeight);
	});
}

function keepSrcUsable(el) {
	var descriptors = {
		get: function get(prop) {
			return el[OFI].img[prop ? prop : 'src'];
		},
		set: function set(value, prop) {
			el[OFI].img[prop ? prop : 'src'] = value;
			nativeSetAttribute.call(el, ("data-ofi-" + prop), value); // preserve for any future cloneNode
			fixOne(el);
			return value;
		}
	};
	Object.defineProperty(el, 'src', descriptors);
	Object.defineProperty(el, 'currentSrc', {
		get: function () { return descriptors.get('currentSrc'); }
	});
	Object.defineProperty(el, 'srcset', {
		get: function () { return descriptors.get('srcset'); },
		set: function (ss) { return descriptors.set(ss, 'srcset'); }
	});
}

function hijackAttributes() {
	function getOfiImageMaybe(el, name) {
		return el[OFI] && el[OFI].img && (name === 'src' || name === 'srcset') ? el[OFI].img : el;
	}
	if (!supportsObjectPosition) {
		HTMLImageElement.prototype.getAttribute = function (name) {
			return nativeGetAttribute.call(getOfiImageMaybe(this, name), name);
		};

		HTMLImageElement.prototype.setAttribute = function (name, value) {
			return nativeSetAttribute.call(getOfiImageMaybe(this, name), name, String(value));
		};
	}
}

function fix(imgs, opts) {
	var startAutoMode = !autoModeEnabled && !imgs;
	opts = opts || {};
	imgs = imgs || 'img';

	if ((supportsObjectPosition && !opts.skipTest) || !supportsOFI) {
		return false;
	}

	// use imgs as a selector or just select all images
	if (imgs === 'img') {
		imgs = document.getElementsByTagName('img');
	} else if (typeof imgs === 'string') {
		imgs = document.querySelectorAll(imgs);
	} else if (!('length' in imgs)) {
		imgs = [imgs];
	}

	// apply fix to all
	for (var i = 0; i < imgs.length; i++) {
		imgs[i][OFI] = imgs[i][OFI] || {
			skipTest: opts.skipTest
		};
		fixOne(imgs[i]);
	}

	if (startAutoMode) {
		document.body.addEventListener('load', function (e) {
			if (e.target.tagName === 'IMG') {
				fix(e.target, {
					skipTest: opts.skipTest
				});
			}
		}, true);
		autoModeEnabled = true;
		imgs = 'img'; // reset to a generic selector for watchMQ
	}

	// if requested, watch media queries for object-fit change
	if (opts.watchMQ) {
		window.addEventListener('resize', fix.bind(null, imgs, {
			skipTest: opts.skipTest
		}));
	}
}

fix.supportsObjectFit = supportsObjectFit;
fix.supportsObjectPosition = supportsObjectPosition;

hijackAttributes();

module.exports = fix;

},{}],22:[function(require,module,exports){
(function(self) {
  'use strict';

  if (self.fetch) {
    return
  }

  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob: 'FileReader' in self && 'Blob' in self && (function() {
      try {
        new Blob()
        return true
      } catch(e) {
        return false
      }
    })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ]

    var isDataView = function(obj) {
      return obj && DataView.prototype.isPrototypeOf(obj)
    }

    var isArrayBufferView = ArrayBuffer.isView || function(obj) {
      return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
    }
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name)
    }
    if (/[^a-z0-9\-#$%&'*+.\^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value)
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift()
        return {done: value === undefined, value: value}
      }
    }

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      }
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {}

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value)
      }, this)
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1])
      }, this)
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name])
      }, this)
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name)
    value = normalizeValue(value)
    var oldValue = this.map[name]
    this.map[name] = oldValue ? oldValue+','+value : value
  }

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)]
  }

  Headers.prototype.get = function(name) {
    name = normalizeName(name)
    return this.has(name) ? this.map[name] : null
  }

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  }

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value)
  }

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this)
      }
    }
  }

  Headers.prototype.keys = function() {
    var items = []
    this.forEach(function(value, name) { items.push(name) })
    return iteratorFor(items)
  }

  Headers.prototype.values = function() {
    var items = []
    this.forEach(function(value) { items.push(value) })
    return iteratorFor(items)
  }

  Headers.prototype.entries = function() {
    var items = []
    this.forEach(function(value, name) { items.push([name, value]) })
    return iteratorFor(items)
  }

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result)
      }
      reader.onerror = function() {
        reject(reader.error)
      }
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader()
    var promise = fileReaderReady(reader)
    reader.readAsArrayBuffer(blob)
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader()
    var promise = fileReaderReady(reader)
    reader.readAsText(blob)
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf)
    var chars = new Array(view.length)

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i])
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength)
      view.set(new Uint8Array(buf))
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false

    this._initBody = function(body) {
      this._bodyInit = body
      if (!body) {
        this._bodyText = ''
      } else if (typeof body === 'string') {
        this._bodyText = body
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString()
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer)
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer])
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body)
      } else {
        throw new Error('unsupported BodyInit type')
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8')
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type)
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8')
        }
      }
    }

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this)
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      }

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      }
    }

    this.text = function() {
      var rejected = consumed(this)
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    }

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      }
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    }

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT']

  function normalizeMethod(method) {
    var upcased = method.toUpperCase()
    return (methods.indexOf(upcased) > -1) ? upcased : method
  }

  function Request(input, options) {
    options = options || {}
    var body = options.body

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url
      this.credentials = input.credentials
      if (!options.headers) {
        this.headers = new Headers(input.headers)
      }
      this.method = input.method
      this.mode = input.mode
      if (!body && input._bodyInit != null) {
        body = input._bodyInit
        input.bodyUsed = true
      }
    } else {
      this.url = String(input)
    }

    this.credentials = options.credentials || this.credentials || 'omit'
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers)
    }
    this.method = normalizeMethod(options.method || this.method || 'GET')
    this.mode = options.mode || this.mode || null
    this.referrer = null

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body)
  }

  Request.prototype.clone = function() {
    return new Request(this, { body: this._bodyInit })
  }

  function decode(body) {
    var form = new FormData()
    body.trim().split('&').forEach(function(bytes) {
      if (bytes) {
        var split = bytes.split('=')
        var name = split.shift().replace(/\+/g, ' ')
        var value = split.join('=').replace(/\+/g, ' ')
        form.append(decodeURIComponent(name), decodeURIComponent(value))
      }
    })
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers()
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ')
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':')
      var key = parts.shift().trim()
      if (key) {
        var value = parts.join(':').trim()
        headers.append(key, value)
      }
    })
    return headers
  }

  Body.call(Request.prototype)

  function Response(bodyInit, options) {
    if (!options) {
      options = {}
    }

    this.type = 'default'
    this.status = options.status === undefined ? 200 : options.status
    this.ok = this.status >= 200 && this.status < 300
    this.statusText = 'statusText' in options ? options.statusText : 'OK'
    this.headers = new Headers(options.headers)
    this.url = options.url || ''
    this._initBody(bodyInit)
  }

  Body.call(Response.prototype)

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  }

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''})
    response.type = 'error'
    return response
  }

  var redirectStatuses = [301, 302, 303, 307, 308]

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  }

  self.Headers = Headers
  self.Request = Request
  self.Response = Response

  self.fetch = function(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init)
      var xhr = new XMLHttpRequest()

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        }
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL')
        var body = 'response' in xhr ? xhr.response : xhr.responseText
        resolve(new Response(body, options))
      }

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'))
      }

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'))
      }

      xhr.open(request.method, request.url, true)

      if (request.credentials === 'include') {
        xhr.withCredentials = true
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob'
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value)
      })

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit)
    })
  }
  self.fetch.polyfill = true
})(typeof self !== 'undefined' ? self : this);

},{}]},{},[1]);
